import React from "react";
import { Row, Col, Typography, Table } from "antd";
import { Link } from "gatsby";
import { columns, dataset } from "../../data/legal-data";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;
const MerchantTerms = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={1}>Merchant Privacy Policy</Title>
              <Heading
                level={2}
                injectionType={4}
                className="pt-[0px] specialty"
              >
                For our consumer Privacy Policy{" "}
                <Link to="/legal/consumer-privacy">click here</Link>
              </Heading>
              <Title level={5}>EFFECTIVE AS OF JULY 3, 2023</Title>
              <p>
                SpotOn Transact, LLC (“SpotOn”) is committed to protecting your
                privacy and are constantly working to give you a safe online
                experience. This “Privacy Policy” governs SpotOn’s data
                collection and usage to facilitate products and services offered
                to Merchants. Our Consumer Privacy Policy includes the data
                collection and usage of Personal Information collected directly
                from an individual by SpotOn or from your direct interaction
                with Merchant products, collected in our Merchant application,
                and data collected by our Merchants when using our products. By
                using SpotOn’s Site, you consent to the data practices described
                in this Privacy Policy. Please read our Privacy Policy for more
                information.
              </p>
              {/* <LegalList listData={legalListMPP} /> */}
              <Title level={2}>1. Definitions</Title>
              <ol type="A">
                <li>
                  <p className="pl-2">
                    “Data” includes both Non-Personal Information and Personally
                    Identifiable Information.
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    “SpotOn”, “we,” “us” and “our” means SpotOn.
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    “Merchant” is a business that offers SpotOn products and
                    services
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    “Non-Personal Information” is any information that is not
                    Personally Identifiable Information (defined below).
                    Non-Personal Information includes, without limitation,
                    Personally Identifiable Information that has been
                    anonymized.
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    “Personal Information,” “Personally Identifiable
                    Information” or “PII” shall have the same meaning as
                    determined by applicable law but includes only information
                    we receive from your use of the Site. To the extent
                    applicable law does not define Personal Information, it is
                    non-public information we receive from your use of the Site
                    that can be used, alone or in combination with other
                    information in our possession, to identify a particular
                    person/individual. It may include information such as name,
                    address, telephone number and other personal information you
                    provide us.
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    “Services” products or services that are or may be provided
                    through the Site or that we may otherwise offer online or
                    offline{" "}
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    “Site” refers to the following websites, as applicable,
                    including any related blogs, domains, mobile sites, online
                    services and applications: SpotOn.com, SpotOn and any other
                    applications from SpotOn Transact, LLC.
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    “You” and “your” mean the individual or entity visiting or
                    using the Site.
                  </p>
                </li>
              </ol>
              <Title level={2}>2. Personal Information We Collect</Title>
              <p>
                We may collect PII from you in a variety of ways, including, but
                not limited to, when you visit the Site, communicate with us
                through the Site, provide PII in connection with other
                activities, products, services, features or resources we make
                available on the Site or through any services we may offer or
                events we may participate in online or offline. We may collect
                your name, mailing address, billing address, phone number,
                payment information, age, gender, email address, preferences or
                interests. We may also collect additional information from you
                depending on the nature of the transaction.
              </p>
              <Title level={3}>Personal Information You Provide Us</Title>
              <p>
                We may collect PII from you in a variety of ways, including, but
                not limited to, when you visit the Site, communicate with us
                through the Site, provide PII in connection with other
                activities, products, services, features or resources we make
                available on the Site or through any services we may offer or
                events we may participate in online or offline. We may collect
                your name, mailing address, billing address, phone number,
                payment information, age, gender, email address, preferences,
                interests and preferences. We may also collect additional
                information from you depending on the nature of the transaction.
              </p>
              <ul type="disc">
                <li className="text-[18px]">
                  <span>Personal information we may collect:</span>
                  <ul type="circle">
                    <li>First and last name</li>
                    <li>Alias</li>
                    <li>Postal/mailing address</li>
                    <li>Email address</li>
                    <li>Password</li>
                    <li>Telephone number</li>
                    <li>Cellular telephone number</li>
                    <li>Geographic area</li>
                    <li>Geolocation data</li>
                    <li>Title</li>
                    <li>Internet protocol address</li>
                    <li>
                      Documents to verify your identity such as drivers license
                    </li>
                    <li>Your occupation</li>
                    <li>
                      The name of the organization you work for/Name of employer
                    </li>
                    <li>
                      General biographical information that you may provide to
                      us
                    </li>
                    <li>
                      <p>Photograph of you</p>
                    </li>
                  </ul>
                </li>
                <li className="text-[18px] mb-4">
                  <span>
                    Commercial information we may collect upon request:
                  </span>
                  <ul type="circle" className="mb-4">
                    <li>Transaction information</li>
                    <li>Services purchased, obtained or considered</li>
                    <li>
                      Your purchasing or consuming history or tendencies, along
                      with other preferences and interests
                    </li>
                    <li>Financial Information about you and/or your company</li>
                    <li>Information about your business</li>
                    <li>Business URL</li>
                    <li>Photographs of your business</li>
                    <li>Facebook and social media</li>
                    <li>
                      <p>
                        Any other information required for the provision of the
                        Services such as to respond to an issue on an account
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                We collect information from you when you (i) visit the site (ii)
                register on the Site, (iii) fill out forms or fields on the Site
                or offline, (iv) complete an application for a product or
                service available through the Site (v) communicate with us by
                email, mail, text, telephone, or other electronic means, (vi)
                use the Site or authorize us to access any financial information
                stored or processed by a third party, or (vii) provide us with
                Personal Information or other information directly.
              </p>
              <p>
                We will treat as personal information any item that, either
                alone or together with other information, we could use to
                identify an individual. Except as described below, we will not
                share with third parties any personal information without your
                permission.
              </p>
              <p>
                By providing your Personal Information to us, you explicitly
                agree to our collection and use of such information as described
                in this Privacy Policy. By providing Personal Information
                belonging to another person, you expressly represent and warrant
                that you have the authority and right to do so, and that you
                obtained all appropriate and required consents, and you also
                indemnify and hold us harmless for any violation of this
                warranty.
              </p>
              <p>
                We may also collect your Personal Information from sources that
                you have authorized to share with us or that you have authorized
                us to obtain information from.
              </p>
              <p>
                We may collect information about you from third parties related
                to transactions you are contemplating entering into with us or
                that you have entered into with us.
              </p>
              <p>
                If you believe we received your personal information from a
                source without authority to share your personal information with
                us, please contact us so that we can resolve your complaint.
              </p>
              <Title level={3}>Information We Automatically Collect</Title>
              <p>
                SpotOn may collect information about you automatically when you
                visit the Site, communicate through the Site, or use any of our
                online Services. We may automatically receive certain
                information your browser or mobile device, such as your browser
                type, the operating system of your device, the URL of the site
                from which you came and the URL of the site you visit when
                leaving the Site or any of our online Services, the equipment
                you use to access the Site, the unique identifier of your mobile
                device and your activities on the Site, such as the webpages you
                view and how you interact with those webpages. We may also
                receive your IP address and the name of your internet service
                provider (“ISP”) or your mobile carrier. Depending on your
                device’s settings, we may also receive location data and other
                types of information sent from third party services or
                GPS-enabled devices.
              </p>
              <p>
                As you use the Site or any of our online Services, we may also
                collect information about such usage and your actions on the
                Site, for example, pages you viewed, access times, and how long
                you spent on a page. We may use cookies to collect such
                information, as described in more detail below. We may anonymize
                and aggregate performance information and site usage for the
                purposes of improving our website and user experience by
                converting Personal information such as cookies and IPs to
                Non-Personal aggregated information.
              </p>
              <Title level={2}>3. Cookies and Tracking Technologies</Title>
              <p>
                The Site or our online Services may send a “cookie” to your
                computer. A cookie is a small file, typically of letters and
                numbers, downloaded onto a device such as a computer or mobile
                device when you access our websites. For more information about
                cookies or local storage, including how to see what cookies have
                been set and how to manage, block and delete them, see:{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.allaboutcookies.org/"
                >
                  http://www.allaboutcookies.org/
                </a>
                . A cookie cannot read data off your hard disk or read cookie
                files created by other sites. Cookies do not damage your system.
                Cookies allow us to recognize you as a user when you return to
                the Site or use the online Services using the same computer and
                web browser. We use cookies to identify which areas of the Site
                or online Services you have visited. We also may use this
                information to better personalize the content you see on the
                Site or online Services. SpotOn does not store unencrypted
                Personal Information in the cookies. We also do not link
                Non-Personal Information from cookies to your Personal
                Information.
              </p>
              <p>
                To help us optimize the Site or for marketing purposes, we may
                allow other authorized third parties to place or recognize
                unique cookies on your browser. Any information provided to
                third parties through cookies will be for enhancing your user
                experience by providing more relevant marketing. Third party
                services and tools we use, which may send cookies to users of
                the Site and may collect information from users.
              </p>
              <p>
                Some browsers may allow you to manage the storage of cookies on
                your device. If supported by your browser, you may set your
                browser to refuse all cookies (or, sometimes all third-party
                cookies) or to alert you when a cookie is placed. However, if
                you select these settings, you may be unable to access certain
                parts of the Site or the online Services. Unless you have
                adjusted your browser setting to refuse cookies, the Site and
                online Services will issue cookies. For more information about
                how to manage your cookies preferences, use the ‘help’ menu of
                your web browser or explore the customer support sections of
                your web browser. To opt-out of all cookies or certain
                advertising cookies, visit the company website for your browser
                for instructions.
              </p>
              <p>
                You can opt-out of receiving personalized ads from advertisers
                and ad networks that are members of the Network Advertising
                Initiative (“NAI”) or who follow the Digital Advertising
                Alliance (“DAA”) Self-Regulatory Principles for Online
                Behavioral Advertising using their respective opt-out tools. The
                NAI's opt-out tool can be found here:{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.networkadvertising.org/choices/"
                >
                  http://www.networkadvertising.org/choices/
                </a>
                . and the DAA's opt out tool can be found here:{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.aboutads.info/choices/"
                >
                  http://www.aboutads.info/choices/
                </a>
                .
              </p>
              <p>
                In addition, your mobile devices may offer settings that enable
                you to make choices about the collection, use, or transfer of
                mobile app information for online behavioral advertising (for
                example, Apple iOS’ Advertising ID and Google Android’s
                Advertising ID). Please note that opting out does not prevent
                the display of all advertisements to you.
              </p>
              <Title level={3}>Embedded Pixels</Title>
              <p>
                We may use on the Site embedded pixel technologies on selected
                pages for the purposes of identifying unique user visits to the
                Site as opposed to aggregate hits. In addition, embedded pixels
                or other technologies may be used in emails to provide
                information on when the email was opened to track marketing
                campaign responsiveness; information collected using these
                technologies may be associated with your email address.
              </p>
              <Title level={3}>Flash Cookies</Title>
              <p>
                We may use flash cookies, also known as “local shared objects,”
                on the Site if it employs or in the future employs flash
                technology. Flash cookies are small files similar to browser
                cookies and are used to remember the site’s settings to
                personalize the look and feel of the site. Like normal cookies,
                flash cookies are represented as small files on your computer.
              </p>
              <Title level={3}>Embedded URLs</Title>
              <p>
                We may also use a tracking technique that employs embedded URLs
                to allow use of the Site without cookies. Embedded URLs allow
                limited information to follow you as you navigate the Site but
                is not associated with your Personal Information and is not used
                beyond the session.
              </p>
              <Title level={3}>Widgets</Title>
              <p>
                The Site may include widgets, which are interactive mini
                programs that run on our Site to provide specific services from
                another company (e.g., links to bookmarked sites). Some
                information, such as your email address, may be collected
                through the widget. Cookies may also be set by the widget to
                enable it to function properly. Information collected by this
                widget is governed by the privacy policy of the company that
                created it.
              </p>
              <Title level={2}>
                4. How we use and share your personally identifiable information
              </Title>
              <p>
                We will use your Personal Information to provide the products &
                services that your personal information was shared with us to
                fulfill. SpotOn may use personal information for the following
                purposes:
              </p>
              <ol>
                <li>
                  <p>
                    To improve customer service: Your information helps us to
                    more effectively communicate with inquiries and existing
                    customers, and to respond to your customer service requests
                    and support needs.
                  </p>
                </li>
                <li>
                  <p>
                    To personalize user experience: We may use information in
                    the aggregate to understand how our users as a group use the
                    Services and resources provided on the Site.
                  </p>
                </li>
                <li>
                  <p>
                    To improve the Site: We continually strive to improve our
                    Site and the Services based on the information and feedback
                    we receive from you.
                  </p>
                </li>
                <li>
                  <p>
                    To provide products or services and to process transactions:
                    Except as otherwise expressly provided in this Privacy
                    Policy, we will use Data only for the purpose of fulfilling
                    our duties and providing the Services. SpotOn may use
                    Non-Personal Information for statistical analysis, product
                    development, research, or other purposes. We may use the
                    information that you provide about yourself or that you have
                    provided to our clients for the purpose of providing and
                    fulfilling the Services. We do not share this information
                    with outside parties except to the extent necessary to
                    provide the Services.
                  </p>
                </li>
                <li>
                  <p>
                    To send periodic mail, emails and surveys: The email address
                    you provide for the Services will only be used to send you
                    information, newsletters, surveys and updates pertaining to
                    the Services requested, provided or that we think you may be
                    interested in. It may also be used to respond to your
                    inquiries and/or other requests or questions.
                  </p>
                </li>
                <li>
                  <p>
                    To develop new products or services: We use your Personal
                    Information and Non-Personal Information for research and
                    commercial purposes. The information we collect may be used
                    to develop new products or services. Except as otherwise
                    provided in this Privacy Policy, we may use your Personal
                    Information and Non-Personal Information internally or,
                    among other things, to offer our own or third-party products
                    and services. Only SpotOn, its subsidiaries, its suppliers
                    and contractors involved in distributing/providing the new
                    product or service will have access to your Personal
                    Information. Our subsidiaries, suppliers and contractors
                    will be required to use any PII we provide to them only for
                    that intended purpose and subject to the terms of this
                    Privacy Policy.
                  </p>
                </li>
                <li>
                  <p>
                    Fulfillment of Obligations: Comply with contractual
                    obligations, relevant industry standards and our policies.
                  </p>
                </li>
                <li>
                  <p>
                    For marketing: Except as otherwise expressly provided for in
                    this Privacy Policy or except as prohibited by applicable
                    law, SpotOn may use your Personal Information and
                    Non-Personal Information to enhance its networking,
                    marketing, social, and recruiting activities, and for other
                    similar business purposes. SpotOn may also use your Personal
                    Information and Non-Personal Information to contact you on
                    behalf of external business partners about a particular
                    offering that may be of interest to you. In these cases,
                    your Personal Information is not transferred to the third
                    party. We may also use Personal Information to provide you
                    information regarding new products or services or to post
                    testimonials from you related to our products or services.
                    Personal Information is not shared with entities outside of
                    SpotOn other than service providers who assist us in
                    carrying out these business functions. SpotOn does not use
                    or disclose sensitive Personal Information, such as race,
                    religion, or political affiliations, without your explicit
                    consent.
                  </p>
                </li>
                <li>
                  <p>
                    Compliance with applicable law: We may disclose your
                    Personal Information as we may in our sole discretion
                    determine is necessary or reasonably required to comply with
                    the law, applicable regulations, court orders or subpoenas,
                    to enforce our Terms of Use, or to protect our rights,
                    property or safety, or the rights, property or safety of
                    others.
                  </p>
                </li>
                <li>
                  <p>
                    Security: Mitigate fraud, enhance the security of the Site
                    and manage institutional risk.
                  </p>
                </li>
                <li>
                  <p>
                    Change in the Site Ownership: If ownership of the Site or of
                    SpotOn changes, whether in whole or in part, information
                    collected through the Site and the Services about you may be
                    transferred to the new owner of the Site and/or SpotOn, as
                    the case may be, and any Services can continue. In that
                    case, your Personal Information would remain subject to the
                    terms and conditions of the applicable version of the
                    Privacy Policy.
                  </p>
                </li>
                <li>
                  <p>
                    Payment Processing: For processing payments related to the
                    products and services we provide you.
                  </p>
                </li>
                <li>
                  <p>
                    Cooperation with Third Parties. As part of the provision of
                    the Services, we may provide access to information you
                    provide to facilitate the provision of Services to you,
                    including, without limitation, the processing of credit
                    applications with us and collections.
                  </p>
                </li>
              </ol>
              <Title level={2}>5. Consent to electronic communications</Title>
              <p>
                We may use your email address to send you messages notifying you
                of important changes to the Services or special offers we think
                you may be interested in. Further, we may contact you by
                telephone if you provide us with your telephone number, to
                communicate with you regarding the Services we are providing to
                you. If you do not want to receive email messages or telephone
                calls, please contact us. Some communications related to ongoing
                transactions or the enforcement of terms you have agreed to
                related to the provision of Services may not be subject to your
                opting out of or choosing not to receive.
              </p>
              <p>
                In order to receive our Services, it may be necessary, as we may
                determine, for you to consent to transacting business with us
                electronically. We may also require you to consent to giving us
                certain disclosures electronically, either through the Site or
                to the email address you provide to us. By entering into any
                agreement for any of the Services, you agree to receive
                electronically all documents, communications, notices, contracts
                and agreements (collectively, “Communications and Agreements”)
                arising from or relating to your use of the Site and the
                Services, including your registration on the Site, your use of
                the Services, and matters involving payment for the purchase of
                our Services.
              </p>
              <ol>
                <li>
                  <p>
                    Opt-Out: You may revoke your consent by contacting us by
                    email at privacy@spoton.com
                  </p>
                </li>
                <li>
                  <p>
                    Scope of Consent: Your consent to receive Communications and
                    Agreements and transact business electronically, and our
                    agreement to do so, applies to any transactions to which
                    such Communications and Agreements apply.
                  </p>
                </li>
              </ol>
              <Title level={2}>
                6. Retention and Storage of personal information
              </Title>
              <p>
                We retain your Personal Information for as long as necessary to
                fulfill the purpose for which it was collected and to comply
                with applicable laws. We use reasonable security precautions to
                protect your information while in storage.
              </p>
              <p>
                To determine the appropriate retention period for Personal
                Information, we consider the amount, nature, and sensitivity of
                the personal information, the potential risk of harm from
                unauthorized use or disclosure of your Personal Information, the
                purposes for which we process your personal information, whether
                we can achieve those purposes through other means, and
                applicable legal requirements.
              </p>
              <Title level={2}>7. Security</Title>
              <p>
                We will use commercially reasonable methods to keep your
                Personal Information securely in our files and systems. Payment
                method data is secured using PCI-DSS standards.When credit card
                numbers are transmitted through a field in a form on the Site
                requesting credit card numbers, it is protected through the use
                of encryption, such as the Secure Socket Layer (SSL) protocol.
              </p>
              <Title level={2}>8. Do Not track</Title>
              <p>
                Your browser may provide you with the option to send a “Do Not
                Track” signal to websites you visit. This signal is used to
                request that websites not send the requesting device cookies,
                but websites have no obligation to respond to such signal or to
                modify their operation. At the current time, the Site is not
                programmed to recognize Do Not Track signals, so the Site will
                not treat you differently if we receive such signals from your
                browser and we may not comply with Do Not Track settings on your
                browser.
              </p>
              <Title level={2}>9. Links to Other Sites</Title>
              <p>
                The Site and the online Services may contain links to other
                websites. SpotOn is not responsible for the actions, practices,
                or content of websites linked to or from the Site or the online
                Services. You understand such websites may require you to agree
                to their terms of use and that we have no control over these
                terms. As always, you understand it is your responsibility to
                verify your legal use of a website and use of information from
                the website with the corresponding website owner.
              </p>
              <Title level={2}>10. Age of Consent </Title>
              <p>
                This Site is only intended for persons 18 years of age or
                older.The Site does not target and is not intended for children
                under the age of 13, and SpotOn will not knowingly collect
                Personal Information directly from them. If SpotOn discovers
                that a child has provided personal data directly through the
                Site, SpotOn will eliminate that data. If you are under the age
                of 18, you must ask a parent or legal guardian for permission
                prior to submitting any information to this Site.
              </p>
              <p>
                If you have knowledge that a child 13 years of age or younger
                has submitted Personally Identifiable Information to us, please
                contact us and we will delete the Personal Information collected
                belonging to that child. You may contact us via email or by
                writing to us at the address below. Parents and guardians can
                also email us at privacy@spoton.com, but before any information
                is disclosed, the parent will be required to provide
                verification of his/her identity and authority related to any
                request. We will only send the information to the parent email
                address in the registration file.
              </p>
              <Title level={2}>11. CAN-SPAM Compliance Notice</Title>
              <p>
                SpotOn fully complies with the federal CAN-SPAM Act. You can
                always opt out of receipt of further email correspondence from
                us.
              </p>
              <Title level={2}>12. California Privacy Rights</Title>
              <p>
                Under Section 1798.83 of the California Civil Code, residents of
                California can obtain certain information from companies with
                whom they have an established business relationship. That
                information is about the Personal Information those companies
                have shared with third parties for direct marketing purposes
                during the preceding calendar year. The law requires companies
                to inform consumers about the categories of Personal Information
                shared with third parties, the names and addresses of those
                third parties, and examples of the services or products marketed
                by those third parties. To request a copy of the information
                disclosure provided by SpotOn under Section 1798.83 of the
                California Civil Code, please contact us via email to
                privacy@spoton.com.
              </p>
              <Title level={2}>
                13. California Consumer Privacy Act (“CCPA”)
              </Title>
              <p>
                To the extent applicable, the following terms in this Section 18
                apply:
              </p>
              <p>
                This Privacy Policy for California residents supplements the
                information contained above in our general privacy policy and
                applies solely to visitors, users and others who reside in the
                State of California. Any inconsistent terms in any other
                sections of the Privacy Policy will be superseded by this
                Section for California residents.
              </p>
              <Title level={3}>Your Rights Under the CCPA</Title>
              <p>
                To the extent applicable to SpotOn, under the CCPA, California
                residents are granted the following rights:
              </p>
              <ol type="A">
                <li>
                  <p className="pl-2">
                    The right to delete personal information businesses have
                    collected from them (subject to some exceptions);
                  </p>
                </li>
                <li>
                  <p className="pl-2">
                    The right to correct inaccurate personal information that
                    businesses have about them;
                  </p>
                </li>
                <li>
                  <p>
                    The right to know what personal information businesses have
                    collected about them and how they use and share it;
                  </p>
                </li>
                <li>
                  <p>
                    The right to opt-out of the sale of their personal
                    information;
                  </p>
                </li>
                <li>
                  <p>
                    The right to opt-out of the sharing of their personal
                    information for cross-context behavioral advertising;
                  </p>
                </li>
                <li>
                  <p>
                    The right to limit the use and disclosure of sensitive
                    personal information collected about them; and
                  </p>
                </li>
                <li>
                  <p>
                    The right to non-discrimination for exercising their CCPA
                    rights.
                  </p>
                </li>
              </ol>
              <p>
                When receiving a request, we will verify that the individual
                making the request is the resident to whom the Personal
                Information subject to the request pertains. California
                residents may exercise their rights themselves or may use an
                authorized agent to make requests to disclose certain
                information about the processing of their Personal Information
                or to delete Personal Information on their behalf. If you use an
                authorized agent to submit a request, we may require that you
                provide us additional information demonstrating that the agent
                is acting on your behalf.
              </p>
              <Title level={3}>
                Categories of Personal Information We Collect
              </Title>
              <p>
                We may collect information as set forth above and also
                information that identifies you, your household or your device
                or is reasonably capable of being connected with or linked to
                you, your household or your device. “Personal Information” does
                not include public information available from government
                records, de-identified or aggregated information.
              </p>
              <Table columns={columns} dataSource={dataset} />
              <Title level={3}>Retention</Title>
              <p>
                We retain your Personal Information for as long as necessary to
                fulfill the purpose for which it was collected and to comply
                with applicable laws. We use reasonable security precautions to
                protect your information while in storage.
              </p>
              <p>
                To determine the appropriate retention period for Personal
                Information, we consider the amount, nature, and sensitivity of
                the personal information, the potential risk of harm from
                unauthorized use or disclosure of your Personal Information, the
                purposes for which we process your personal information, whether
                we can achieve those purposes through other means, and
                applicable legal requirements.
              </p>
              <Title level={3}>How We Use Your Information</Title>
              <p>
                See above for how we use your information. Except as otherwise
                stated in this Privacy Policy, we will not collect additional
                categories of Personal Information or use the Personal
                Information we collected for materially different, unrelated, or
                incompatible purposes without providing you notice.
              </p>
              <Title level={3}>Requests to Know</Title>
              <p>
                You have the right to request that we disclose certain
                information to you about our collection and use of your Personal
                Information over the prior 12 months. Once we receive and
                confirm your verifiable consumer request, we will disclose to
                you:
              </p>
              <ul>
                <li>
                  <p>
                    The categories of Personal Information we collected about
                    you.
                  </p>
                </li>
                <li>
                  <p>
                    The categories of sources for the Personal Information we
                    collected about you.
                  </p>
                </li>
                <li>
                  <p>
                    The business or commercial purpose for collecting or selling
                    your Personal Information.
                  </p>
                </li>
                <li>
                  <p>
                    The categories of third parties with whom we share your
                    Personal Information.
                  </p>
                </li>
                <li>
                  <p>
                    The specific pieces of Personal Information we have
                    collected about you
                  </p>
                </li>
                <li>
                  <p>
                    If we sold/shared or disclosed your Personal Information for
                    a business purpose, which we do not do and will not do, two
                    separate lists disclosing:
                    <ul>
                      <li>
                        <p>
                          In the case of a sale/share, the categories of
                          Personal Information sold/shared about you and the
                          categories of third parties to whom the Personal
                          Information was sold/shared, by category of Personal
                          Information for each category of third parties; and
                        </p>
                      </li>
                      <li>
                        <p>
                          In the case of disclosure for a business purpose, the
                          categories of Personal Information that were sold,
                          shared, or disclosed for a business purpose and if
                          Personal Information has not been sold, shared, or
                          disclosed for a business purpose, that we did not do
                          so.
                        </p>
                      </li>
                    </ul>
                  </p>
                </li>
              </ul>
              <p>
                We are not required to (i) retain your Personal Information if
                collected for a single one-time transaction if, in the ordinary
                course of business, that information would not be retained and
                (2) re-identify or otherwise link any data that, in the ordinary
                course of business, is not maintained in a way that would be
                considered Personal Information.
              </p>
              <Title level={3}>Requests to Delete</Title>
              <p>
                You have the right to request that we delete any of your
                Personal Information that we collected from you and retained,
                subject to certain exceptions. Once we receive and confirm your
                verifiable consumer request, we will delete (and direct our
                service providers to delete) your Personal Information from our
                records, unless an exception applies, which we will disclose to
                you.
              </p>
              <p>
                We are not required to grant your deletion request if retaining
                the Personal Information is necessary for us or our service
                providers to:
              </p>
              <ol>
                <li>
                  <p>
                    Complete the transaction for which the Personal Information
                    was collected, fulfill the terms of a written warranty or
                    product recall, provide a good or service requested by you,
                    or reasonably anticipated within the context of our ongoing
                    business relationship with you, or otherwise perform a
                    contract between us and you.
                  </p>
                </li>
                <li>
                  <p>
                    Detect security incidents, protect against malicious,
                    deceptive, fraudulent or illegal activity; or prosecute
                    those responsible for that activity.
                  </p>
                </li>
                <li>
                  <p>
                    Debug to identify and repair errors that impact existing
                    intended functionality.
                  </p>
                </li>
                <li>
                  <p>
                    Exercise free speech, ensure the right of another consumer
                    to exercise that consumer’s right of free speech, or
                    exercise another right provided for by law.
                  </p>
                </li>
                <li>
                  <p>
                    Comply with the California Electronic Communications Privacy
                    Act.
                  </p>
                </li>
                <li>
                  <p>
                    Engage in public or peer-reviewed scientific, historical, or
                    statistical research in the public interest when the
                    deletion of the public information is likely to render
                    impossible or seriously impair the achievement of such
                    research, if the consumer has provided informed consent.
                  </p>
                </li>
                <li>
                  <p>
                    For solely internal uses that are reasonably aligned with
                    your expectations based on your relationship with us.
                  </p>
                </li>
                <li>
                  <p>Comply with a legal or regulatory obligation.</p>
                </li>
                <li>
                  <p>
                    Internally make otherwise lawful uses of your Personal
                    Information that are compatible with the context in which
                    you provided your Personal Information.
                  </p>
                </li>
              </ol>
              <Title level={3}>Right to Correct</Title>
              <p>
                You have the right to request to the extent that we maintain
                inaccurate personal information about you, to correct that
                inaccurate personal information, taking into account the nature
                of the personal information and the purposes of the processing
                of the personal information.
              </p>
              <Title level={3}>How to Submit a Request</Title>
              <p>
                To request this information, please submit a verifiable consumer
                request to us by either:
              </p>
              <ul>
                <li>
                  <p>
                    Emailing us at privacy@spoton.com and submit your request in
                    the body of the email with the subject heading: “CCPA
                    Consumer Request.”
                  </p>
                </li>
              </ul>
              <p>
                Only you, or someone legally authorized to act on your behalf,
                may make a verifiable consumer request related to your Personal
                Information. We are not required to provide you with Personal
                Information more than twice in a 12-month period.
              </p>
              <p>
                We will not be able to respond to your request or provide you
                with Personal Information if we cannot verify your identity or
                authority to make the request and confirm the Personal
                Information relates to you.
              </p>
              <p>
                We will try to respond to your request within forty-five (45)
                days of receipt of your written request. If we require more time
                (up to 90 days), we will inform you of the extension period in
                writing. We will deliver our written response by mail or
                electronically, at your option. Any disclosures we provide will
                only cover the 12-month period preceding the verifiable consumer
                request’s receipt. The response we provide will also explain the
                reasons we cannot comply with a request, if applicable. For data
                portability requests, we will select a format to provide your
                Personal Information that is machine-readable and should allow
                you to transmit the information from one entity to another
                entity without hindrance, specifically by electronic mail
                communication.
              </p>
              <p>
                We do not charge a fee to process or respond to your verifiable
                consumer request unless it is excessive, repetitive, or
                manifestly unfounded. If we determine that the request warrants
                a fee, we will tell you why we made that decision and provide
                you with a cost estimate before completing your request.
              </p>
              <Title level={3}>Opt-Out of Sale/Share Request</Title>
              <p>
                Once you make an opt-out request, we will wait at least twelve
                (12) months before asking you to reauthorize Personal
                Information sharing with third parties. However, you may change
                your mind and opt back into Personal Information sharing with
                third parties at any time by emailing us at privacy@SpotOn.com.
              </p>
              <p>
                We will only use Personal Information provided in an opt-out
                request to review and comply with the request. We will act upon
                your opt-out request within 15 days of receipt.
              </p>
              <Title level={3}>Non-Discrimination</Title>
              <p>
                We will not discriminate against you for exercising any of your
                CCPA rights. Unless permitted by the CCPA, we will not:
              </p>
              <ul>
                <li>
                  <p>
                    Deny you goods or services, except to the extent that
                    providing the Services requires the disclosure of Personal
                    Information that you have decided not to disclose or allow
                    us to use for the purpose of providing the Services.
                  </p>
                </li>
                <li>
                  <p>
                    harge you different prices or rates for goods or services,
                    including through granting discounts or other benefits, or
                    imposing penalties.
                  </p>
                </li>
                <li>
                  <p>
                    Provide you a different level or quality of goods or
                    services.
                  </p>
                </li>
                <li>
                  <p>
                    Suggest that you may receive a different price or rate for
                    goods or services or a different level or quality of goods
                    or services.
                  </p>
                </li>
              </ul>
              <p>
                However, we may offer you certain financial incentives permitted
                by the CCPA that can result in different prices, rates, or
                quality levels. Any CCPA-permitted financial incentive we offer
                will reasonably relate to your Personal Information's value and
                contain written terms that describe the program's material
                aspects. Participation in a financial incentive program requires
                your prior opt in consent, which you may revoke at any time.
              </p>
              <Title level={2}>14. YOUR NEVADA PRIVACY RIGHTS</Title>
              <p>
                Nevada law (SB 220) permits customers in Nevada to opt-out of
                the sale of certain kinds of personal information. A sale under
                Nevada law is the transfer of this personal information to third
                parties for monetary consideration so these third parties can
                then re-sell or license the sold information. We do not sell
                your Personal Information to third parties as defined in Nevada
                law. If you are a Nevada resident and wish to opt-out of the
                sale of your personal information, should we change our
                practices in the future, you must send a request by email to
                privacy@SpotOn.com.
              </p>
              <Title level={2}>15. NOTICE TO VIRGINIA USERS</Title>
              <p>
                We will use commercially reasonable methods to keep your
                Personal Information securely in our files and systems. When
                credit card numbers are transmitted through a field in a form on
                the Site requesting credit card numbers, it is protected through
                the use of encryption, such as the Secure Socket Layer (SSL)
                protocol.
              </p>
              <Title level={2}>12. Do not track</Title>
              <p>
                We set forth above in our Privacy Policy the categories of
                personal data we process, the purpose for processing personal
                data, the categories of personal data shared, and the categories
                of third parties with whom personal data is shared.
              </p>
              <p>
                If you are a Virginia Consumer and would like to exercise your
                rights pursuant to the Virginia Consumer Data Protection Act
                (VCDPA), and any implementing regulations adopted thereunder,
                please send a request by email to privacy@SpotOn.com to submit a
                request.
              </p>
              <p>
                Virginia Consumers have the following rights, all subject to the
                meanings and exceptions set forth in the VCDPA:
              </p>
              <ul>
                <li>
                  <p>
                    To confirm whether we are processing your Personal Data and
                    request to access such data ("Right to Access").
                  </p>
                </li>
                <li>
                  <p>
                    That we correct inaccurate Personal Data we hold about you
                    (“Right to Correct”).
                  </p>
                </li>
                <li>
                  <p>
                    That we delete the Personal Data provided by you or obtained
                    about you ("Right to Delete").
                  </p>
                </li>
                <li>
                  <p>
                    To obtain a copy of the Personal Data previously provided by
                    you to us and, to the extent feasible, in a readily usable
                    format to allow data portability (“Right to Obtain”).
                  </p>
                </li>
                <li>
                  <p>
                    To opt- out of the processing of your Personal Data for the
                    purposes of targeted advertising (“Right to Opt-Out of
                    Targeted Advertising”).
                  </p>
                </li>
              </ul>
              <p>
                <b>VCDPA Appeals</b>
              </p>
              <p>
                Pursuant to the VCDPA, if, for any reason, you would like to
                appeal our decision relating to your request, you have the right
                to submit an appeal and can do so by sending a request by email
                to privacy@SpotOn.com to submit a request or call us toll-free
                at 877-814-4102 to submit a request. Please include your full
                name, the basis for your appeal, and any additional information
                to consider.
              </p>
              <Title level={2}>16. NOTICE TO EUROPEAN USERS</Title>
              <p>
                This Privacy Policy for users in the European Economic Area
                (“EEA”) supplements the information contained above in our
                general privacy policy and applies solely to all visitors, users
                and others who are located in the EEA. Any inconsistent terms in
                any other sections of the Privacy Policy will be superseded by
                this Section for EEA residents.
              </p>
              <Title level={3}>Personal Information.</Title>
              <p>
                References to personal information or Personally Identifiable
                Information in this Privacy Policy are equivalent to “personal
                data” governed by the General Data Protection Regulation (EU/UK)
                (collectively, “GDPR”).
              </p>
              <Title level={3}>Controller.</Title>
              <p>
                SpotOn is the controller of your personal information covered by
                this Privacy Policy for purposes of the GDPR. Our contact
                information is provided below.
              </p>
              <Title level={3}>Legal Basis for Processing.</Title>
              <p>
                We use your personal information only as permitted by law. Our
                legal bases for processing personal information described in
                this Privacy Policy are as follows:
              </p>
              <ul>
                <li>
                  <p>To provide Services to you.</p>
                </li>
                <li>
                  <p>For research and development.</p>
                </li>
                <li>
                  <p>To send you marketing communications.</p>
                </li>
                <li>
                  <p>For compliance, fraud prevention and safety.</p>
                </li>
                <li>
                  <p>To create anonymous data.</p>
                </li>
                <li>
                  <p>To comply with applicable law.</p>
                </li>
                <li>
                  <p>Processing of personal information with your consent.</p>
                </li>
              </ul>
              <Title level={3}>Your rights</Title>
              <p>
                The GDPR gives you certain rights regarding your personal
                information. If you are located within the EEA, you may ask us
                to take the following actions in relation to your personal
                information that we hold:
              </p>
              <ul>
                <li>
                  <p>
                    Access.Provide you with information about our processing of
                    your personal information and give you access to your
                    personal information.
                  </p>
                </li>
                <li>
                  <p>
                    Correct.Update or correct inaccuracies in your personal
                    information.
                  </p>
                </li>
                <li>
                  <p>Delete.Delete your personal information.</p>
                </li>
                <li>
                  <p>
                    Transfer.Transfer a machine-readable copy of your personal
                    information to you or a third-party of your choice.
                  </p>
                </li>
                <li>
                  <p>
                    Restrict.Restrict the processing of your personal
                    information.
                  </p>
                </li>
                <li>
                  <p>
                    Object. Object to our reliance on our legitimate interests
                    as the basis of our processing of your personal information
                    that impacts your rights.
                  </p>
                </li>
              </ul>
              <p>You may submit these requests by:</p>
              <ul>
                <li>
                  <p>
                    Emailing us at{" "}
                    <a href="mailto:privacy@spoton.com">privacy@SpotOn.com</a>.
                    Please include in the subject heading of your email: “GDPR
                    Request.”
                  </p>
                </li>
              </ul>
              <p>
                We may request specific information from you to help us confirm
                your identity and process your request. Applicable law may
                require or permit us to decline your request. If we decline your
                request, we will tell you why, subject to legal restrictions.
              </p>
              <p>
                If you would like to submit a complaint about our use of your
                personal information or our response to your requests regarding
                your personal information, you may contact us by email or mail,
                as specified above, or submit a complaint to the data protection
                regulator in your jurisdiction. You can find your data
                protection regulator here:
              </p>
              <ul>
                <li>
                  <p>
                    EU:{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
                    >
                      https://edpb.europa.eu/about-edpb/about-edpb/members_en
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    UK:{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://ico.org.uk/global/contact-us/"
                    >
                      https://ico.org.uk/global/contact-us/
                    </a>
                  </p>
                </li>
              </ul>
              <Title level={3}>Cross-Border Data Transfer</Title>
              <p>
                Personal information voluntarily submitted to SpotOn online, via
                electronic communication, or otherwise, may be maintained or
                accessed in servers or files in the United States of America,
                which the European Union has not deemed to provide “adequate”
                privacy protection. If you do not consent to having your
                information processed and stored in the United States of
                America, please do not provide it to SpotOn.
              </p>
              <Title level={2}>17. Amendments</Title>
              <p>
                SpotOn reserves the right to change the Privacy Policy from time
                to time at its sole discretion with or without notice. Any
                revisions are effective immediately once posted on the Site.
                Your continued use of the Site shall indicate your acceptance of
                the revision. Revisions to the Privacy Policy regarding the use
                of Personal Information are not retroactive.
              </p>
              <Title level={2}>18. European Union Disclosure</Title>
              <p>
                Personal Information voluntarily submitted to SpotOn online, via
                electronic communication, or otherwise, may be maintained or
                accessed in servers or files in the United States of America,
                which the European Union has not deemed to provide “adequate”
                privacy protection. If you do not consent to having your
                information processed and stored in the United States of
                America, please do not provide it to SpotOn. SpotOn contends
                that the General Data Protection Regulation (EU) 2016/679 does
                not apply to its processing of personal information and that it
                is not within the jurisdiction of the EU. All processing of PII
                is governed by the applicable laws of the United States of
                America.
              </p>
              <Title level={2}>19. Contact</Title>
              <p>
                If you have any questions about the Privacy Policy, or to report
                a violation of the Terms of Use, please contact us at{" "}
                <a href="mailto:privacy@SpotOn.com">privacy@SpotOn.com</a> or by
                mail at:
              </p>
              <p>
                <u>SpotOn</u>
                <div>
                  Email us at:{" "}
                  <a className="ml-1" href="mailto:privacy@SpotOn.com">
                    privacy@SpotOn.com
                  </a>
                </div>
              </p>
            </div>
          </Col>

          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary target="/legal/user-terms" ctaTitle="User Terms" />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle="Reserve Terms"
              />
              <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default MerchantTerms;
